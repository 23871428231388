import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

const ThreeDPipe = ({ defects }) => {
    const mountRef = useRef(null);

    useEffect(() => {
        const mount = mountRef.current;
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(30, mount.clientWidth / mount.clientHeight, 1, 1000);
        camera.position.set(10, 0, 20);
        camera.lookAt(scene.position);

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(mount.clientWidth, mount.clientHeight);
        renderer.setClearColor(0x404040);
        mount.appendChild(renderer.domElement);

        const controls = new OrbitControls(camera, renderer.domElement);

        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.setScalar(1);
        scene.add(light, new THREE.AmbientLight(0xffffff, 0.5));

        const r = 1, R = 1.25;

        // Pipe
        const pipeShape = new THREE.Shape();
        pipeShape.absarc(0, 0, R, 0, Math.PI * 2);
        const holePath = new THREE.Path();
        holePath.absarc(0, 0, r, 0, Math.PI * 2);
        pipeShape.holes.push(holePath);

        const pipeGeometry = new THREE.ExtrudeGeometry(pipeShape, {
            curveSegments: 100,
            depth: 20,
            bevelEnabled: false
        });
        pipeGeometry.center();

        const pipeMaterial = new THREE.MeshLambertMaterial({ color: 'lightgray' });
        const pipeMesh = new THREE.Mesh(pipeGeometry, pipeMaterial);
        scene.add(pipeMesh);

        // Function to get defect color and style
        const getDefectStyle = (defectLabel) => {
            switch (defectLabel.toString()) {
                case "0":
                    return { color: '#000000', name: 'Normal' };
                case "1":
                    return { color: '#FBFB09', name: 'Weld' };
                case "2":
                    return { color: '#36f60f', name: 'Corrosion' };
                case "3":
                    return { color: '#0079fa', name: 'Grooving' };
                case "4":
                    return { color: '#ff0909', name: 'Pitting' };
                case "5":
                    return { color: '#b01808', name: 'Crack' };
                default:
                    return { color: '#C9CBCF', name: 'Unknown' };
            }
        };

        const loader = new FontLoader();
        let fontLoaded = false;

        const defectsGroup = new THREE.Group();
        scene.add(defectsGroup);

        const loadFont = () => {
            return new Promise((resolve, reject) => {
                loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', resolve, undefined, reject);
            });
        };

        loadFont().then(font => {
            fontLoaded = true;

            defects.forEach((defect) => {
                const { depth, label, distance } = defect;
                const { color, name } = getDefectStyle(label);

                // Calculate position based on distance along the pipe
                const angle = (distance / 1000) * Math.PI * 2; // Assuming 1 unit = 1 meter
                const posX = R * Math.cos(angle);
                const posY = R * Math.sin(angle);

                let defectMesh;

                // Create shape based on defect label
                if (label === 5) {
                    const crackShape = new THREE.Shape();
                    crackShape.moveTo(0, 0);
                    crackShape.lineTo(0.2, 0.1);
                    crackShape.lineTo(0.4, -0.2);
                    crackShape.lineTo(0.6, 0.3);
                    crackShape.lineTo(0.8, -0.1);
                    crackShape.lineTo(1.0, 0.2);

                    const crackExtrudeSettings = {
                        depth: 0.05,
                        bevelEnabled: false
                    };
                    const crackGeometry = new THREE.ExtrudeGeometry(crackShape, crackExtrudeSettings);

                    const crackMaterial = new THREE.MeshLambertMaterial({ color });
                    defectMesh = new THREE.Mesh(crackGeometry, crackMaterial);

                    defectMesh.position.set(posX, posY, depth);
                    defectMesh.rotation.z = angle;
                } else if (label === 4) {
                    // Pitting defect shape
                    const pittingShape = new THREE.Shape();
                    pittingShape.moveTo(0, 0);
                    pittingShape.lineTo(0.1, 0.1);
                    pittingShape.lineTo(0.2, -0.1);
                    pittingShape.lineTo(0.3, 0.1);
                    pittingShape.lineTo(0.4, -0.1);

                    const extrudeSettings = {
                        depth: 0.05,
                        bevelEnabled: false
                    };
                    const pittingGeometry = new THREE.ExtrudeGeometry(pittingShape, extrudeSettings);
                    defectMesh = new THREE.Mesh(pittingGeometry, new THREE.MeshLambertMaterial({ color }));
                } else if (label === 3) {
                    // Grooving defect shape
                    const groovingShape = new THREE.Shape();
                    groovingShape.moveTo(0, 0);
                    groovingShape.lineTo(0.2, 0);
                    groovingShape.lineTo(0.1, 0.2);
                    groovingShape.lineTo(0, 0);

                    const extrudeSettings = {
                        depth: 0.05,
                        bevelEnabled: false
                    };
                    const groovingGeometry = new THREE.ExtrudeGeometry(groovingShape, extrudeSettings);
                    defectMesh = new THREE.Mesh(groovingGeometry, new THREE.MeshLambertMaterial({ color }));
                } else if (label === 2) {
                    // Corrosion defect shape
                    const corrosionShape = new THREE.Shape();
                    corrosionShape.moveTo(0, 0);
                    corrosionShape.bezierCurveTo(0.2, 0.2, 0.4, -0.2, 0.6, 0.2);
                    corrosionShape.bezierCurveTo(0.8, 0.6, 1.0, -0.4, 1.2, 0.2);

                    const extrudeSettings = {
                        depth: 0.05,
                        bevelEnabled: false
                    };
                    const corrosionGeometry = new THREE.ExtrudeGeometry(corrosionShape, extrudeSettings);
                    defectMesh = new THREE.Mesh(corrosionGeometry, new THREE.MeshLambertMaterial({ color }));
                } else if (label === 1) {
                    // Weld defect shape
                    const weldShape = new THREE.Shape();
                    weldShape.moveTo(0, 0);
                    weldShape.lineTo(0.5, 0);
                    weldShape.lineTo(0.5, 0.05);
                    weldShape.lineTo(0, 0.05);
                    weldShape.lineTo(0, 0);

                    const extrudeSettings = {
                        depth: 0.05,
                        bevelEnabled: false
                    };
                    const weldGeometry = new THREE.ExtrudeGeometry(weldShape, extrudeSettings);
                    defectMesh = new THREE.Mesh(weldGeometry, new THREE.MeshLambertMaterial({ color }));
                } else {
                    // Default defect shape (sphere)
                    const damageMaterial = new THREE.MeshLambertMaterial({ color });
                    const damageGeometry = new THREE.SphereGeometry(0.2, 16, 16);
                    defectMesh = new THREE.Mesh(damageGeometry, damageMaterial);
                }

                if (label !== 5) {
                    defectMesh.position.set(posX, posY, depth); 
                }

                const textGeometry = new TextGeometry(name, {
                    font: font,
                    size: 0.3,
                    depth: 0.05
                });
                const textMaterial = new THREE.MeshBasicMaterial({ color: '#fff' });
                const textMesh = new THREE.Mesh(textGeometry, textMaterial);
                textMesh.position.set(posX, posY, depth + 0.5); 

                const defectGroup = new THREE.Group();
                defectGroup.add(defectMesh);
                defectGroup.add(textMesh);

                defectsGroup.add(defectGroup);
            });
        }).catch(err => {
            console.error('Error loading font:', err);
        });

        const handleResize = () => {
            camera.aspect = mount.clientWidth / mount.clientHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(mount.clientWidth, mount.clientHeight);
        };

        window.addEventListener('resize', handleResize);

        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };
        animate();

        return () => {
            window.removeEventListener('resize', handleResize);
            mount.removeChild(renderer.domElement);
        };
    }, [defects]);

    return <div ref={mountRef} style={{ width: '98%', height: '35vh', cursor: 'all-scroll' }} />;
};

export default ThreeDPipe;
