import React from 'react';
import { Container, Row, Col, Navbar, Nav, Card } from 'react-bootstrap';
import { FaInfoCircle, FaPhone } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <Container fluid style={{ backgroundColor: "#BED1DF", height: '100vh' }}>
            <Navbar expand="lg" style={{ borderBottom: '1px solid' }}>
                <Navbar.Brand as={Link} to="/" style={{ fontSize: '25px', fontWeight: 'bold' }}>GIS Pipeline</Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Link as={Link} to="/About" style={{ color: "green" }}><FaInfoCircle /> About</Nav.Link>
                    <Nav.Link as={Link} to="/Contact" style={{ color: "green" }}><FaPhone /> Contact</Nav.Link>
                </Nav>
            </Navbar>
            <Container className="py-5">
                <Row>
                    <Col md={6}>
                        <Card className="mb-3">
                            <Card.Body>
                                <Card.Title>About GIS Pipeline</Card.Title>
                                <Card.Text>
                                    GIS Pipeline is a platform dedicated to managing and visualizing geographical data related to pipelines. Our mission is to provide innovative solutions for pipeline management and analysis, helping organizations optimize their operations and ensure safety and reliability.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="mb-3">
                            <Card.Body>
                                <Card.Title>Our Mission</Card.Title>
                                <Card.Text>
                                    Our mission is to provide innovative solutions for pipeline management and analysis, helping organizations optimize their operations and ensure safety and reliability.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Card className="mb-3">
                            <Card.Body>
                                <Card.Title>Who We Serve</Card.Title>
                                <Card.Text>
                                    Whether you're a pipeline operator, an engineer, or a researcher, GIS Pipeline offers tools and resources to streamline your workflow and make informed decisions.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="mb-3">
                            <Card.Body>
                                <Card.Title>Our Vision</Card.Title>
                                <Card.Text>
                                    Our vision is to be the leading platform for pipeline management and analysis, trusted by organizations worldwide for its reliability, innovation, and user-friendly interface.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default About;
