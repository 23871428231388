import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaChartBar, FaDownload } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';

Chart.register(zoomPlugin);

const Plot1Graph = ({ data, style, mapImageUrl, onCaptureMapImage }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const iframeRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedGraph, setSelectedGraph] = useState('plot1');
    const [modalChartHeight, setModalChartHeight] = useState('400px');
    const [includeMapInPdf, setIncludeMapInPdf] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const getPointStyle = (defectLabel) => {
        switch (defectLabel) {
            case "0":
                return { pointStyle: 'circle', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderColor: 'rgba(0, 0, 0, 1)' };
            case "1":
                return { pointStyle: 'rect', backgroundColor: '#FBFB09', borderColor: '#FBFB16' };
            case "2":
                return { pointStyle: 'triangle', backgroundColor: '#36f60f', borderColor: '#57F737' };
            case "3":
                return { pointStyle: 'rectRot', backgroundColor: '#0079fa', borderColor: '#0079fa' };
            case "4":
                return { pointStyle: 'cross', backgroundColor: '#ff0909', borderColor: '#ff0909' };
            case "5":
                return { pointStyle: 'star', backgroundColor: '#b01808', borderColor: '#b01808' };
            default:
                return { pointStyle: 'circle', backgroundColor: 'rgba(201, 203, 207, 0.4)', borderColor: 'rgba(201, 203, 207, 1)' };
        }
    };

    const getLineStyle = (defectLabel) => {
        switch (defectLabel) {
            case "1":
                return { borderDash: [] };
            case "5":
                return { borderDash: [5, 5] };
            default:
                return { borderDash: [] };
        }
    };

    const getDefectTypeName = (defectLabel) => {
        switch (defectLabel) {
            case "0":
                return "Normal";
            case "1":
                return "Weld";
            case "2":
                return "Corrosion";
            case "3":
                return "Grooving";
            case "4":
                return "Pitting";
            case "5":
                return "Crack";
            default:
                return "Unknown";
        }
    };

    const formatTime = (seconds) => {
        const date = new Date(seconds * 1000);
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const secondsStr = String(date.getUTCSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${secondsStr}`;
    };

    useEffect(() => {
        if (!chartRef.current || !data) return;

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
            const parseTime = (timeStr) => {
                if (!timeStr) return 0;
                const [datePart, timePart] = timeStr.split(' ');
                const [year, month, day] = datePart.split('-').map(Number);
                const [hours, minutes, seconds] = timePart.split(':').map(Number);

                const date = new Date(year, month - 1, day, hours, minutes, seconds);
                return date.getTime() / 1000;
            };

            const defectData = data.map(entry => ({
                x: parseTime(entry.Time),
                y: parseInt(entry.Defect_Label) * -1,
                defectLabel: entry.Defect_Label.toString(),
            }));

            const datasets = [];
            const defectGroups = {};

            defectData.forEach(point => {
                if (!defectGroups[point.defectLabel]) {
                    defectGroups[point.defectLabel] = {
                        label: getDefectTypeName(point.defectLabel),
                        data: [],
                        ...getPointStyle(point.defectLabel),
                        borderWidth: 2,
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        showLine: true,
                        borderDash: getLineStyle(point.defectLabel).borderDash,
                    };
                }
                defectGroups[point.defectLabel].data.push(point);
            });

            Object.keys(defectGroups).forEach(key => {
                datasets.push(defectGroups[key]);
            });

            const chartData = {
                datasets: datasets,
            };

            const chartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 2,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Time (s)',
                        },
                        type: 'linear',
                        ticks: {
                            callback: function(value) {
                                return formatTime(value);
                            },
                            beginAtZero: true,
                        },
                        position: 'top',
                    },
                    y: {
                        display: false,
                    },
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            label: function (context) {
                                const defectLabel = context.raw.defectLabel;
                                const defectType = getDefectTypeName(defectLabel);
                                const time = formatTime(context.raw.x);
                                return `Defect: ${defectType} at Time ${time}`;
                            }
                        }
                    },
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                        },
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true,
                            },
                            mode: 'x',
                        },
                    },
                },
            };

            chartInstance.current = new Chart(ctx, {
                type: 'line',
                data: chartData,
                options: chartOptions,
            });
        }

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [data]);

    useEffect(() => {
        if (includeMapInPdf && mapImageUrl) {
            generatePDF(true);
        }
    }, [mapImageUrl, includeMapInPdf]);

    const generatePDF = (includeMap) => {
        if (!chartRef.current) {
            console.error('Chart not loaded');
            return;
        }

        const pdf = new jsPDF({
            orientation: 'landscape',
        });

        html2canvas(chartRef.current).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

            if (includeMap && mapImageUrl) {
                const mapImage = new Image();
                mapImage.src = mapImageUrl;
                mapImage.onload = () => {
                    const mapImageWidth = pdfWidth - 20;
                    const mapImageHeight = (mapImage.height * mapImageWidth) / mapImage.width;
                    pdf.addPage();
                    pdf.addImage(mapImage, 'JPEG', 10, 10, mapImageWidth, mapImageHeight);
                    pdf.save('GraphWithMap.pdf');
                };
            } else {
                pdf.save('GraphWithoutMap.pdf');
            }
        });
    };

    const downloadPDF = (includeMap) => {
        if (includeMap) {
            setIncludeMapInPdf(true);
            onCaptureMapImage();
        } else {
            generatePDF(false);
        }
    };

    const handleShowModal = () => {
        setShowModal(true);
        setModalChartHeight('400px');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalChartHeight('400px');
    };

    const handleGraphTypeChange = (event) => {
        setSelectedGraph(event.target.value);
    };

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    return (
        <div>
            <h5>Plotting Defects in Pipeline Based on Time (s) &nbsp;&nbsp;
                <FaDownload title='Export Graph without Map' onClick={() => downloadPDF(false)} style={{ fontSize: '20px', cursor: 'pointer', color: '#0d6efd' }} />&nbsp;&nbsp;&nbsp;
                <FaChartBar onClick={handleShowModal} title='View Graph in Large Screen' style={{ marginRight: "10px", fontSize: '28px', cursor: 'pointer', color: '#0d6efd' }} />
                <FaDownload title='Export Graph with Map' onClick={() => downloadPDF(true)} style={{ fontSize: '20px', cursor: 'pointer', color: "#0d6efd" }} />
            </h5>
            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="mt-0 mx-2 d-flex">
                            <div className="">
                                <label className="label" htmlFor="plot1">
                                    Plotting Defects and Corresponding Depth in Pipeline
                                </label>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data && (
                        <div>
                            {selectedGraph === 'plot1' && (
                                <div>
                                    <Plot1Graph data={data} style={{ height: modalChartHeight }} />
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div>
                <iframe ref={iframeRef} title='iframe' src={mapImageUrl} style={{ display: 'none' }} onLoad={handleIframeLoad}></iframe>
                <canvas ref={chartRef} style={style}></canvas>
            </div>
        </div>
    );
};

export default Plot1Graph;
