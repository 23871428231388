import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Map, View } from 'ol';
import moment from 'moment';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS';
import { fromLonLat, toLonLat } from 'ol/proj';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import ThreeDPipe from './ThreeDPipe';
import NavigationBar from './components/NavigationBar'; 
import LegendImage from './components/LegendImage'; 
import Plot1Graph from './components/Plot1Graph';
import Plot4Graph from './components/Plot4Graph';
import olMapScreenshot from 'ol-map-screenshot';
import Overlay from 'ol/Overlay'; 
import 'ol/ol.css';
import './App.css';
import 'leaflet/dist/leaflet.css';
import { FaTimes, FaUpload, FaDownload, FaFile } from 'react-icons/fa';

function MapComponent() {
    const mapRef = useRef(null);
    const fileInputRefs = {
        magnetometer1: useRef(null),
        magnetometer2: useRef(null),
        txt: useRef(null),
        diameter: useRef(null)
    };
    
    const [isUploadDivVisible, setIsUploadDivVisible] = useState(false);
    const [showCard, setShowCard] = useState(true);
    const [showSidebar, setShowSidebar] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState({ filesData: true, pipeline: false });
    const [files, setFiles] = useState([]);
    const [fileData, setFileData] = useState(null);
    const [selectedGraph, setSelectedGraph] = useState('plot1');
    const [mapImageUrl, setMapImageUrl] = useState(null);
    const [map, setMap] = useState(null);
    const [defectValue, setDefectValue] = useState('');
    const [depthValue, setDepthValue] = useState('');
    const [featureIdValue, setFeatureIdValue] = useState('');
    const [m_file_idValue, setM_file_idValue] = useState('');
    const [commentValue, setCommentValue] = useState('');
    const [propertiesValue, setPropertiesValue] = useState('');
    const [cardWidth, setCardWidth] = useState(window.innerWidth <= 768 ? 100 : 50);
    const [selectedFiles, setSelectedFiles] = useState({
        magnetometer1: null,
        magnetometer2: null,
        txt: null,
        diameter: ''
    });
    const popupRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [pingOverlay, setPingOverlay] = useState(null);
    const [defects, setDefects] = useState([]);

    useEffect(() => {
        if (!mapRef.current) return;

        const initialCoord = fromLonLat([72.958236, 21.631591]);

        const wmsSource = new TileWMS({
            url: 'https://geoserver.proeffico.com/geoserver/wms',
            params: {
                'LAYERS': `${checkboxValue.filesData ? 'gis_pipeline:files_data' : ''}${checkboxValue.filesData && checkboxValue.pipeline ? ',' : ''}${checkboxValue.pipeline ? 'gis_pipeline:tbl_line_geom' : ''}`,
                'TILED': true
            },
            serverType: 'geoserver',
            crossOrigin: 'anonymous'
        });

        const wmsLayer = new TileLayer({
            source: wmsSource,
        });

        const osmLayer = new TileLayer({
            source: new OSM(),
        });

        const mapInstance = new Map({
            layers: [osmLayer, wmsLayer],
            target: mapRef.current,
            view: new View({
                center: initialCoord,
                zoom: 13,
            }),
        });

        const popup = new Overlay({
            element: document.getElementById('popup'),
            autoPan: true,
            autoPanAnimation: { duration: 250 }
        });
        mapInstance.addOverlay(popup);
        popupRef.current = popup;

        const pingElement = document.createElement('div');
        pingElement.className = 'ping-overlay';
        const pingOverlay = new Overlay({
            element: pingElement,
            positioning: 'center-center',
            stopEvent: false,
        });
        mapInstance.addOverlay(pingOverlay);
        setPingOverlay(pingOverlay);

        mapInstance.on('singleclick', async (evt) => {
            setShowSidebar(true);
            const coordinate = evt.coordinate;
            let [lon, lat] = toLonLat(coordinate);
            lon = lon.toFixed(6);
            lat = lat.toFixed(6);
            const layersParam = `${checkboxValue.filesData ? 'gis_pipeline:files_data' : ''}${checkboxValue.filesData && checkboxValue.pipeline ? ',' : ''}${checkboxValue.pipeline ? 'gis_pipeline:tbl_line_geom' : ''}`;

            const url = `https://geoserver.proeffico.com/geoserver/gis_pipeline/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layersParam}&maxFeatures=5&outputFormat=application/json&CQL_FILTER=DWithin(geom,POINT(${lon} ${lat}),10,feet)`;

            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();  
                if (data.features.length > 0) {
                    // Update defects state
                    const newDefects = data.features.map((feature, index) => {
                        return {
                            depth: feature.properties.Defect_Label, 
                            label: feature.properties.Defect_Label,
                            distance: feature.properties.distance 
                        };
                    });
                    setDefects(newDefects);
                    const feature = data.features[0];
                    const properties = feature.properties;
                    const featureId = feature.id.split('.')[1];
                    setDepthValue(properties.Depth_Label);
                    setDefectValue(properties.Defect_Label);
                    setFeatureIdValue(featureId);
                    setM_file_idValue(properties.m_file_id);
                    setCommentValue('');
                    setPropertiesValue(properties);
                    const defectCoordinate = feature.geometry.coordinates;
                    const transformedDefectCoordinate = fromLonLat(defectCoordinate);
                    pingOverlay.setPosition(transformedDefectCoordinate);
                } else {
                    setDepthValue('');
                    setDefectValue('');
                    setFeatureIdValue('');
                    setM_file_idValue('');
                    setCommentValue('');
                    setPropertiesValue('');
                    pingOverlay.setPosition(undefined);
                    setDefects([]); 
                    Swal.fire('No Data', 'No feature data found at this location', 'warning');
                }
            } catch (error) {
                console.error('Fetch error:', error);
                Swal.fire('Error', 'Failed to fetch feature info', 'error');
            }
        });

        mapInstance.on('pointermove', (evt) => {
            if (evt.dragging) return;
            const pixel = mapInstance.getEventPixel(evt.originalEvent);
            const hit = mapInstance.hasFeatureAtPixel(pixel);
            mapInstance.getTargetElement().style.cursor = hit ? 'pointer' : '';
        });

        setMap(mapInstance);

        return () => mapInstance.setTarget(null);
    }, [mapRef, checkboxValue]);


    
    const mapScreenshotParam = {
        dim: [190, 160]
    };

    const handleToggleCard = () => {
        setShowCard(!showCard);
    };

    const handleCheckboxChange = (value) => {
        setCheckboxValue(value);
    };

    const handleCloseSidebar = () => {
        setShowSidebar(false);
    };

    const handleIconClick = (inputType) => {
        fileInputRefs[inputType].current.click();
    };

    const handleFileChange = (event, inputType) => {
        const files = event.target?.files;
    
        if (inputType === 'diameter') {
            setSelectedFiles({
                ...selectedFiles,
                [inputType]: event.target.value
            });
        } else if (files && files.length > 0) {
            const file = files[0];
            const extension = file.name.split('.').pop().toLowerCase();
            if (['csv', 'xls', 'xlsx', 'txt'].includes(extension)) {
                setSelectedFiles({
                    ...selectedFiles,
                    [inputType]: file
                });
            } else {
                Swal.fire('Invalid File Type', 'Only CSV, XLS, XLSX and TXT files are accepted.', 'error');
                setSelectedFiles({
                    ...selectedFiles,
                    [inputType]: null
                });
            }
        } else {
            setSelectedFiles({
                ...selectedFiles,
                [inputType]: null
            });
        }
    };
        

    const handleSubmit = async () => {

        if (!selectedFiles.magnetometer1) {
            Swal.fire('Warning', 'Magnetometer File-1 is required', 'warning');
            return;
        }
        if (!selectedFiles.magnetometer2) {
            Swal.fire('Warning', 'Magnetometer File-2 is required', 'warning');
            return;
        }
        if (!selectedFiles.txt) {
            Swal.fire('Warning', 'GPS File is required', 'warning');
            return;
        }
        if (!selectedFiles.diameter) {
            Swal.fire('Warning', 'Diameter is required', 'warning');
            return;
        }
    
        const formData = new FormData();
        formData.append('magnetometer1', selectedFiles.magnetometer1);
        formData.append('magnetometer2', selectedFiles.magnetometer2);
        formData.append('gps', selectedFiles.txt);
        formData.append('diameter', selectedFiles.diameter);
    
        try {
            const response = await axios.post('https://gisapi.proeffico.com/api/addFilesToProcessed', formData);
            if (response.status === 201) {
                Swal.fire('Success', response.data.message, 'success').then(() => {
                    var file_id1 = response.data.file_id;
                    var file_path1 = response.data.file_path;
                    var extension1 = response.data.extension;
                    finalSubmit(file_id1, file_path1, extension1);
                });
            } else {
                Swal.fire('Error', 'Failed to upload files', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Error uploading files', 'error');
        }
    };
    
   const finalSubmit = async (file_id1, file_path1, extension1) => {
        const formData = {
            "file_id": file_id1,
            "file_path": file_path1,
            "extension": extension1
        };
        try {
            const response = await fetch('https://gisapi.proeffico.com/api/processFileData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            if (data.status === true) {
                window.location.reload();
            } else {
                Swal.fire('Error', data.message, 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Error uploading files', 'error');
        }
    };
   const handleSubmitLabels = async () => {
    if (defectValue === null || defectValue === undefined || defectValue === '') {
        Swal.fire('Warning', 'Defect value is required', 'warning');
        return;
    }
    if (depthValue === null || depthValue === undefined || depthValue === '') {
        Swal.fire('Warning', 'Depth value is required', 'warning');
        return;
    }
    if (!featureIdValue) {
        Swal.fire('Warning', 'Id is required', 'warning');
        return;
    }
    if (!m_file_idValue) {
        Swal.fire('Warning', 'File Id is required', 'warning');
        return;
    }
        const formData = {
            "file_id": m_file_idValue,
            "id": featureIdValue,
            "Defect_Label": defectValue,
            "Depth_Label": depthValue,
            "comment": commentValue,
        };
        try {
            const response = await fetch('https://gisapi.proeffico.com/api/updateFilesData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            if (data.status === true) {
                Swal.fire('Success', data.message, 'success').then( function() {
                    window.location.reload();
                });
            } else {
                Swal.fire('Error', data.message, 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Error uploading files', 'error');
        }
    };

    const handleMouseDown = () => {
        setIsResizing(true);
    };

    const handleMouseMove = useCallback((e) => {
        if (!isResizing) return;

        const newCardWidth = ((window.innerWidth - e.pageX) / window.innerWidth) * 100;

        if (newCardWidth >= 20 && newCardWidth <= 80) {
            setCardWidth(newCardWidth);
        }
    }, [isResizing]);

    const handleMouseUp = useCallback(() => {
        setIsResizing(false);
    }, []);

    useEffect(() => {
        if (!isResizing) return;

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing, handleMouseMove, handleMouseUp]);

    useEffect(() => {
        const handleResize = () => {
            setCardWidth(window.innerWidth <= 768 ? 100 : 50);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await axios.post('https://gisapi.proeffico.com/api/getUploadedFile');
                if (response.data.status) {
                    setFiles(response.data.data || []);
                    if (response.data.data.length > 0) {
                        viewGraphs(response.data.data[0].id)();
                    }
                } else {
                    throw new Error(response.data.message || 'Error fetching files');
                }
            } catch (error) {
                Swal.fire('Error', error.message, 'error');
            }
        };

        fetchFiles();
    }, []);
    

    const viewGraphs = (id) => async () => {
        try {
            const response = await axios.post('https://gisapi.proeffico.com/api/getUploadedFile', { file_id: id });
            setFileData(response.data.data);
        } catch (error) {
            Swal.fire('Error', 'Error fetching file data', 'error');
        }
    };

    const handleGraphTypeChange = (event) => {
        setSelectedGraph(event.target.value);
    };

    const handleShowDiv = () => {
        setIsUploadDivVisible(prevState => !prevState);
    };


    const handleCaptureMapImage = async () => {
        try {
            const response = await olMapScreenshot.getScreenshot(map, mapScreenshotParam);
            setMapImageUrl(response.img);
        } catch (ex) {
            console.error('Error capturing screenshot:', ex);
            Swal.fire('Error', ex.message, 'error');
        }
    };

    // const downloadImage = (dataUrl, filename) => {
    //     const link = document.createElement('a');
    //     link.href = dataUrl;
    //     link.download = filename;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    return (
        <>
            <NavigationBar handleToggleCard={handleToggleCard} handleCheckboxChange={handleCheckboxChange} />
            <div className="map-container" style={{ width: '100%', display: 'flex', backgroundColor: "rgb(191 191 191)" }}>
                <div id="iframecontainer" className='mt-2' style={{ position: 'relative', width: window.innerWidth <= 768 ? '100%' : showCard ? `${100 - cardWidth}%` : '100%' }}>
                    <div className='card border border-1 p-0' style={{ height: '87vh' }}>
                        <div id='map' ref={mapRef} style={{ width: '100%', height: '100%', backgroundColor: "#FFFFFF" }}></div>
                    </div>
                </div>
                {showCard && (
                    <>
                        <div
                            style={{
                                width: '10px',
                                cursor: 'col-resize',
                                position: 'relative',
                                top: 0,
                                bottom: 0,
                                zIndex: 1000,
                                backgroundColor: 'transparent'
                            }}
                            onMouseDown={handleMouseDown}
                        />
                        <div id="textareacontainer" className='mt-2' style={{ width: `${cardWidth}%`, position: 'relative' }}>
                            <div className="card p-2" style={{ backgroundColor: "rgb(51, 51, 51)", height: '87vh', overflowY: 'auto' }}>
                                <div className="card col-md-12 mt-1" style={{ backgroundColor: "#fff", height: "82vh", overflowY: 'auto' }}>
                                    <div className="d-flex justify-content-between mx-2">
                                        <h5 className="mt-2 mx-2">Recent Transactions</h5>
                                        <button onClick={handleShowDiv} title='Upload Transaction' className='mt-2 btn text-white btn-dark'  style={{ marginRight: "10px" }}>Upload</button>
                                    </div>
                                    <Tabs style={{ marginLeft: '2%' }}>
                                        <TabList>
                                            {files.slice(0, 5).map((file, index) => {
                                                const formattedDate = moment(file.uploaded_at).fromNow();
                                                return (
                                                    <Tab key={index} onClick={() => viewGraphs(file.id)}>
                                                        {`File-${index + 1} (${formattedDate})`}
                                                    </Tab>
                                                );
                                            })}
                                        </TabList>
                                        {files.slice(0, 5).map((file, index) => {
                                            const formattedDate = moment(file.uploaded_at).format('MMMM DD, YYYY, hh:mm:ss A z');
                                            return (
                                                <TabPanel key={index}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <p>{file.file} &nbsp;&nbsp;&nbsp; {formattedDate}</p>
                                                        <div className='d-flex align-items-center'>
                                                            <a href={file.url} target="_self" rel="noopener noreferrer" download={file.file}>
                                                                <FaDownload title='Export merged file' style={{ marginRight: "10px", fontSize: '26px' }} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            );
                                        })}
                                    </Tabs>                            
                                   {isUploadDivVisible && (
                                        <div id='uploadDiv'>
                                            <hr />
                                            <h5 className="mx-2">Upload Transaction</h5>
                                            <div className="d-flex mx-4">
                                                <div className="d-flex" onClick={() => handleIconClick('magnetometer1')}>
                                                    <label className="form-label mt-2" htmlFor="MGM File-1">MGM-1 : </label>&nbsp;&nbsp;
                                                    <FaUpload title='Magnetometer first file' style={{ marginTop: '14%' }} className='upload-icon text-primary' />
                                                    <input ref={fileInputRefs.magnetometer1} type="file" style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 'magnetometer1')} />
                                                </div>&nbsp;&nbsp;
                                                <div className="d-flex" onClick={() => handleIconClick('magnetometer2')}>
                                                    <label className="form-label mt-2" htmlFor="MGM File-2">MGM-2 : </label>&nbsp;&nbsp;
                                                    <FaUpload title='Magnetometer Second file' style={{ marginTop: '14%' }} className='upload-icon text-primary' />
                                                    <input ref={fileInputRefs.magnetometer2} type="file" style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 'magnetometer2')} />
                                                </div>&nbsp;&nbsp;
                                                <div className="d-flex" onClick={() => handleIconClick('txt')}>
                                                    <label className="form-label mt-2" htmlFor="GPS FIle">GPS : </label>&nbsp;&nbsp;
                                                    <FaUpload title='txt file' style={{ marginTop: '20%' }} className='upload-icon text-primary' />
                                                    <input ref={fileInputRefs.txt} type="file" style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 'txt')} />
                                                </div>&nbsp;&nbsp;
                                                <div className='mt-0'>
                                                <label className="form-label mt-2" htmlFor="GPS FIle">Dia : </label>&nbsp;&nbsp;
                                                    <input style={{ width: "50%" }} type="text" placeholder='Diameter' ref={fileInputRefs.diameter} onChange={(e) => handleFileChange(e, 'diameter')} />
                                                </div>
                                            </div>
                                            <div className='mx-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <button className="btn bg-dark text-white mt-2" onClick={handleSubmit}> Submit </button>
                                            </div>
                                            <div className="d-flex justify-content-start mx-2">
                                                {Object.keys(selectedFiles).map((inputType, index) => (
                                                    inputType !== 'diameter' && selectedFiles[inputType] && (
                                                        <div key={index} className='d-flex flex-column ml-2 align-items-center'>
                                                            <FaFile title={`Selected ${inputType}`} className='mt-3 upload-icon' style={{ color: 'yellow' }} />
                                                            <p className="mt-0 mx-4 bold">{selectedFiles[inputType].name}</p>
                                                        </div>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    )}  

                                    <div className="d-flex justify-content-between">
                                        <h5 className="mt-2 mx-2">Graphs</h5>
                                    </div>
                                    <div className="mt-0 mx-2 d-flex">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="graphType"
                                                id="plot1" value="plot1" checked={selectedGraph === 'plot1'} onChange={handleGraphTypeChange} />
                                            <label className="form-check-label" htmlFor="plot1">
                                                Plotting Defects in Pipeline
                                            </label>
                                        </div>&nbsp;&nbsp;&nbsp;
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="graphType" id="plot4" value="plot4" checked={selectedGraph === 'plot4'} onChange={handleGraphTypeChange} />
                                            <label className="form-check-label" htmlFor="plot4">
                                                Plotting Defects and Corresponding Depth in Pipeline
                                            </label>
                                        </div>
                                    </div>
                                    {fileData && (
                                        <div className="mt-2 mx-2">
                                            {selectedGraph === 'plot1' && (
                                                <div>
                                                    <Plot1Graph data={fileData} mapImageUrl={mapImageUrl} showModal={showModal} onCaptureMapImage={handleCaptureMapImage} style={{ height: '250px' }} />
                                                </div>
                                            )}
                                            {selectedGraph === 'plot4' && (
                                                <div>
                                                    <Plot4Graph data={fileData} mapImageUrl={mapImageUrl} showModal={showModal} onCaptureMapImage={handleCaptureMapImage}  style={{ height: '250px' }}/>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                   
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showSidebar && (
                    <div className="sidebar" style={{ position: 'fixed', left: 0, top: 70, bottom: 0, width: '300px', backgroundColor: '#fff', boxShadow: '2px 0 5px rgba(0,0,0,0.5)', zIndex: 1000, padding: '20px', overflowY: 'auto' }}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>More Details</h5>
                            <FaTimes style={{ cursor: 'pointer' }} onClick={handleCloseSidebar} />
                        </div>
                        <div className='alert alert-success p-1'>
                            <div className='text-center'><h6 className='mb-2 fw-bold'>Defect Details</h6></div>
                            <p className='mb-0'><span className='fw-bold'>Lat: </span>{propertiesValue.Latitude}<span className='fw-bold'> Long: </span>{propertiesValue.Longitude}</p>
                            <p className='mb-0'><span className='fw-bold'>Time: </span>{propertiesValue.Time}</p>
                            <p className='mb-0'><span className='fw-bold'>Defect Value: </span>{propertiesValue.Defect_Label === 0  ? "Normal" : propertiesValue.Defect_Label === 1  ? "Weld" : propertiesValue.Defect_Label === 2  ? "Corrosion" : propertiesValue.Defect_Label === 3  ? "Grooving" : propertiesValue.Defect_Label === 4  ? "Pitting" : propertiesValue.Defect_Label === 5  ? "Crack" : ''}</p>
                            <p className='mb-0'><span className='fw-bold'>Depth Value: </span>{propertiesValue.Depth_Label === 0 ? "Normal" : propertiesValue.Depth_Label === 1 ? "0-25%" : propertiesValue.Depth_Label === 2 ? "25-50%" : propertiesValue.Depth_Label === 3 ? "50-75%" : propertiesValue.Depth_Label === 4 ? "75-100%" : ''}</p>
                            <p className='mb-0'><span className='fw-bold'>Distance: </span>{propertiesValue && propertiesValue.distance !== undefined ? propertiesValue.distance.toFixed(6) : ''}</p>

                        </div>
                        <div className='alert alert-warning p-1'>
                            <div className='text-center'><h6 className='mb-2 fw-bold'>Update Labels</h6></div>
                            <p className='mb-0'><span className='fw-bold'>Defect Label: </span>&nbsp;
                            <select  name="defectLabels" id="defectLabels" value={defectValue} onChange={(e) => setDefectValue(e.target.value)} style={{border:"none"}}>
                                <option className='text-xs' hidden style={{ fontSize: "8px!important" }}>Select Defect</option>
                                <option value="0">Normal</option>
                                <option value="1">Weld</option>
                                <option value="2">Corrosion</option>
                                <option value="3">Grooving</option>
                                <option value="4">Pitting</option>
                                <option value="5">Crack</option>
                            </select></p>
                            <p className='mb-0 mt-2'><span className='fw-bold'>Depth Label: </span>&nbsp;&nbsp;
                            <select className='formControl' name="depthLabels" id="depthLabels" value={depthValue} onChange={(e) => setDepthValue(e.target.value)} style={{border:"none"}}>
                            <option hidden>Select Depth</option>
                                <option value="0">Normal</option>
                                <option value="1">0-25%</option>
                                <option value="2">25-50%</option>
                                <option value="3">50-75%</option>
                                <option value="4">75-100%</option>
                            </select></p>
                            <p className='mb-0 mt-2'><span className='fw-bold'>Comment: </span>&nbsp;&nbsp;
                                <textarea cols={26} type='text' name='commentValue' id='commentValue' value={commentValue} onChange={(e) => setCommentValue(e.target.value)} style={{border:"none"}}></textarea></p>
                            <div className='mt-2 d-flex justify-content-end'><Button className='btn btn-sm' style={{backgroundColor: "#f95c38", border: "red"}}  onClick={handleSubmitLabels}>Submit</Button></div>
                        </div>
                        <div className='alert alert-info p-1' style={{ textAlign: 'center' }}>
                            <LegendImage />
                        </div>
                        <div className='alert alert-success p-1'>
                            <ThreeDPipe defects={defects} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default MapComponent;
