import React from 'react';

const LegendImage = () => {
  const legendUrl = "https://geoserver.proeffico.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=gis_pipeline:files_data";

  return (
    <div>
      <img 
        src={legendUrl} 
        alt="Legend Graphic"
        onError={(e) => { e.target.onerror = null; e.target.src = "fallback-image.png"; }}
      />
    </div>
  );
};

export default LegendImage;
