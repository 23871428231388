import React from 'react';
import { Container, Row, Col, Navbar, Nav, Card } from 'react-bootstrap';
import { FaInfoCircle, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ContactUs = () => {
    return (
        <Container fluid style={{ backgroundColor: "#BED1DF", height:'100vh'}}>
            <Navbar expand="lg" style={{ borderBottom: '1px solid' }}>
                <Navbar.Brand as={Link} to="/" style={{ fontSize: '25px', fontWeight: 'bold' }}>GIS Pipeline</Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Link as={Link} to="/About" style={{ color: "green" }}><FaInfoCircle /> About</Nav.Link>
                    <Nav.Link as={Link} to="/Contact" style={{ color: "green" }}><FaPhone /> Contact</Nav.Link>
                </Nav>
            </Navbar>
            <Container className="py-5">
                <Row className="justify-content-center">
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Contact Us</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquam urna vel est sollicitudin, id mattis arcu lacinia. Nullam ultricies arcu magna, et ultrices magna eleifend nec.
                                </Card.Text>
                                <Card.Text>
                                    Fusce nec sollicitudin nulla. Ut congue felis et arcu ultrices, ut tincidunt quam ullamcorper. Nullam ut erat quis nunc suscipit rhoncus ut a magna.
                                </Card.Text>
                                <Card.Text>
                                    In non lacinia eros, in consequat enim. Sed venenatis tristique ante, nec malesuada velit faucibus et. Mauris maximus lacus nec orci varius, a mattis urna laoreet.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default ContactUs;
