import React, { useState } from 'react';
import { Navbar, NavDropdown, Form, Button } from 'react-bootstrap';
import { FaLayerGroup } from 'react-icons/fa';
import { LuExpand } from "react-icons/lu";
// import { Link } from 'react-router-dom';

function NavigationBar({ handleToggleCard, handleCheckboxChange }) {
    const [checkboxValue, setCheckboxValue] = useState({ filesData: true, pipeline: false });

    const handleChange = (name, value) => {
        const updatedCheckboxValue = { ...checkboxValue, [name]: value };
        setCheckboxValue(updatedCheckboxValue);
        handleCheckboxChange(updatedCheckboxValue); 
    };
    return (
        <Navbar expand="lg p-2" style={{ backgroundColor: '#333', color: '#fff', width: '100%' }}>
        <Navbar.Brand href="#" style={{ fontSize: '25px', fontWeight: 'bold', color: 'white' }}>
        <img src="./GIS/logo-2.png" alt="Logo" style={{ width: '100px', marginRight: '10px', marginLeft: '5px' }} />
        GIS Pipeline
    </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav" style={{display:"flex", justifyContent: 'flex-end', marginRight : "2%"}}>
                {/* <Nav className="ml-auto d-flex" style={{flexDirection: "row"}}>
                    <Nav.Link as={Link} to="/About" style={{ color: '#fff' }}><FaInfoCircle /> About</Nav.Link>
                    <Nav.Link as={Link} to="/Contact" style={{ color: '#fff' }}><FaPhone /> Contact</Nav.Link>
                </Nav> */}
                &nbsp;&nbsp;&nbsp;
                <NavDropdown title={<FaLayerGroup color="orange" />} id="basic-nav-dropdown" className='navbar-toggler' style={{ display: "block", fontSize: "99%", color: "green", borderColor: "#fff" }}>
                <Form.Group className='mx-2'>
                        <Form.Check type="checkbox" label="Defects"
                            id="defects-checkbox"checked={checkboxValue.filesData} onChange={(e) => handleChange('filesData', e.target.checked)} />
                        <Form.Check type="checkbox" label="Pipeline" id="pipeline-checkbox"
                            checked={checkboxValue.pipeline} onChange={(e) => handleChange('pipeline', e.target.checked)}/>
                    </Form.Group>
                </NavDropdown>&nbsp;&nbsp;&nbsp;    
                <Button variant="link" onClick={handleToggleCard} style={{ color: '#fff', borderColor: '#fff', height: "30px" }}>
                    <LuExpand style={{ marginBottom: '10px' }} />
                </Button>
            </Navbar.Collapse>  
        </Navbar>
    );
}

export default NavigationBar;
